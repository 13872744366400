import React from 'react';
import PropTypes from 'prop-types';
import Image from './image';

interface HeroProps {
  siteTitle: string;
}
const Hero = ({siteTitle}: HeroProps) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '1rem 1rem',
    }}>
    <h1 style={{textAlign: 'center'}}>Me Logged!</h1>
    <p style={{textAlign: 'center', maxWidth: 440}}>
      A logger for the things you need to remember you did.
    </p>
    <p style={{textAlign: 'center', maxWidth: 440}}>
      Friction-free personal stat counter and analytics.
    </p>
    <div style={{margin: 60, width: `250px`, position: 'relative'}}>
      <div
        style={{
          position: 'absolute',
          width: '250px',
          top: 0,
        }}>
        <Image />
      </div>
    </div>
  </div>
);

Hero.propTypes = {
  siteTitle: PropTypes.string,
};

Hero.defaultProps = {
  siteTitle: ``,
};

export default Hero;
